<template>
  <b-container
    fluid="xl"
  >
    <div class="d-flex pt-4 pb-4">
      <div
        class="h3 mb-0 mr-auto"
      >
        Очереди
      </div>

      <router-link
        to="/queues/tags"
        class="ml-2"
      >
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
        >
          Тэги
        </b-button>
      </router-link>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        size="sm"
        @click="openNewQueueModal"
      >
        Новая очередь
      </b-button>
    </div>

    <div class="d-flex mb-3">
      <b-form-input
        v-model="query"
        type="text"
        placeholder="Введите название или описание"
        debounce="300"
        @keyup.native.enter="queuesFetch"
      />

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-2"
        @click="queuesFetch"
      >
        <b-icon icon="search" />
      </b-button>
    </div>

    <div
      v-if="!totalCount && !isLoading"
    >
      Ничего не найдено
    </div>

    <template v-else>
      <div class="queues-info-wrapper pr-1">
        <div
          v-if="isLoading"
          class="d-flex justify-content-center mt-5"
        >
          <b-spinner
            label="Loading..."
            variant="primary"
            style="width: 5rem; height: 5rem;"
          />
        </div>

        <queue-info
          v-for="(queue) in filteredQueues"
          :key="queue.id"
          :queue="queue"
          class="mb-3"
        />
      </div>
    </template>
  </b-container>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import QueueInfo from '@/components/Queues/QueueInfo';

export default {
  name: 'Queues',
  page: {
    title: 'CRM Doctis - Очереди',
  },
  components: {
    QueueInfo,
  },
  mixins: [mixinRoles],
  data() {
    return {
      query: '',
      isLoading: false,
    };
  },
  computed: {
    queues() {
      return this.$store.state.Queues.queues;
    },
    filteredQueues() {
      if (!this.query) return this.queues;

      return this.queues.filter((queue) => {
        const query = this.query?.toLowerCase();
        const name = queue.name?.toLowerCase();
        const description = queue.description?.toLowerCase();

        return description?.includes(query) || name?.includes(query);
      });
    },
    totalCount() {
      return this.filteredQueues.length;
    },
  },
  async created() {
    await this.queuesFetch();

    Bus.$on('queues:fetch', this.queuesFetch);
  },
  beforeDestroy() {
    Bus.$off('queues:fetch', this.queuesFetch);
  },
  methods: {
    openNewQueueModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'QueueEditModal',
        props: {
          // tag: this.tag,
          afterSave: () => this.queuesFetch(),
        },
      });
    },
    async queuesFetch() {
      if (this.isLoading) return;

      this.isLoading = true;
      try {
        await this.$store.dispatch(this.$types.QUEUES_FETCH, { save: true });
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.queues-info-wrapper {
  height: calc(100vh - 150px);
  overflow: auto;
}

</style>
